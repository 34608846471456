.dashboard_container {
  @apply w-full h-full flex flex-col xl:flex-row gap-y-5 p-3 pb-14;
}

.dashboard_col1 {
  @apply w-full xl:w-[60%] 2xl:w-[67%] h-full xl:px-3;
}

.dashboard_col2 {
  @apply w-full xl:w-[40%] 2xl:w-[33%] h-full flex flex-col gap-y-3;
}

.dashboard_dashboardFilter_container {
  @apply flex flex-row items-start xl:items-center lg:justify-end gap-5 xl:gap-12 w-full;
}

.dashboard_dashboardFilter_separates {
  @apply flex flex-row gap-5 items-start;
}

.dashboard_dashboardFilter_details {
  @apply flex flex-row gap-3 items-start lg:items-center;
}

.dashboard_dashboardFilter_update {
  @apply font-medium text-body1 mt-[6px] text-[#6B7280];
}

.refreshIcon {
  @apply bg-primary-main rounded-md py-[9px] px-3 flex justify-center items-center cursor-pointer;
}

.dashboard_info_cards {
  @apply w-full grid grid-cols-1 gap-x-3 gap-y-5 py-10;

  @media screen and (min-width: 1360px) {
    @apply grid-cols-2;
  }
}

.dashboard_totalfleet_card {
  @apply h-[222px] text-others-white bg-[#57C2E4] shadow-md rounded-md flex  flex-col p-5 bg-[url('/svgs/TotalFleet.svg')] bg-no-repeat bg-right;
}

.dashboard_carbonfootprint_card {
  @apply h-[222px] text-others-white bg-primary-main shadow-md rounded-md flex  flex-col p-5 bg-[url('/svgs/CarbonFootprintReductoin2.svg')] bg-no-repeat bg-right;
}

.dashboard_carbonfootprint_title {
  @apply font-normal text-mediumtext 2xl:text-subtitle2 pb-2;
}

.dashboard_carbonfootprint_detail {
  @apply w-full 2xl:w-[68%] grid grid-cols-2 gap-x-5;
}

.dashboard_carbonfootprint_data {
  @apply flex flex-col;
}

.dashboard_carbonfootprint_thankyou {
  @apply flex flex-col font-normal text-body2 py-3;
}

.dashboard_datboxes {
  @apply w-full grid grid-cols-2 2xl:grid-cols-4 gap-3;
}

.dashboarddata_cards {
  @apply w-full xl:px-3 pt-5 2xl:pt-10 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 2xl:grid-cols-2 gap-x-3;
}

.dashboardcard_title {
  @apply font-medium text-mediumtext 2xl:text-subtitle2 text-gray-900 py-5;
}

.dashboardcard_component1 {
  @apply w-full lg:h-[450px];
}

.dashboardcard_component2 {
  @apply w-full lg:h-[676px];
}

.dashboard_feedback {
  @apply w-full;
}

.dashboard_fleetaprrovedrequest {
  @apply w-full xl:h-[464px];
}

.dashboardFilter_select {
  @apply w-[112px] bg-white;
}
