.fleetaprrovedrequest_container {
  @apply w-full h-full bg-others-white rounded-md shadow-md;
}

.fleetaprrovedrequest_title {
  @apply font-medium text-mediumtext 2xl:text-subtitle2 flex justify-center py-3 text-gray-900;
}

.fleetaprrovedrequest_details {
  @apply w-full flex justify-center mt-5 items-center font-semibold text-font-textcolor1;
}

.fleetaprrovedrequest_total {
  @apply w-[full] flex justify-start ml-[-60px];
}

.fleetaprrovedrequest_total_bubble {
  @apply rounded-full bg-info-main bg-opacity-20 w-[230px] h-[230px] flex flex-col justify-center items-center;
}

.fleetaprrovedrequest_approve {
  @apply w-full flex justify-end -mt-[100px] ml-[60px];
}

.fleetaprrovedrequest_approve_bubble {
  @apply rounded-full bg-primary-main bg-opacity-20 w-[147px] h-[147px] flex flex-col justify-center items-center;
}

.fleetaprrovedrequest_desc {
  @apply w-full px-3 flex justify-center text-body2 text-font-textcolor1 pb-10 mt-10;
}

.fleetaprrovedrequest_graph_separate {
  @apply w-auto;
  // @apply w-[60%] lg:w-[42%] xl:w-[90%] 2xl:w-[80%];

  // @media screen and (min-width: 1201px) and (max-width: 1279px) {
  //   @apply w-[45%];
  // }
}

.fleetaprrovedrequest_text {
  @apply text-[42px] leading-[38px];
}
