.energyusage_container {
  @apply w-full h-full bg-white rounded-md shadow-md px-4;

  .energyusage_menu {
    @apply flex justify-end items-center px-5 py-3;
  }
  .energyusage_graph_detail {
    @apply h-[68%] w-full;

    .energyusage_graph {
      @apply w-full h-[80%] p-0 m-0;
    }

    .energyusage_legends {
      @apply w-full h-[20%] flex justify-center gap-x-3;

      .energyusage_legend_item {
        @apply flex items-center gap-x-2;
      }
    }
  }

  .energyusage_data {
    @apply text-font-textcolor1 py-5 border-t-1 w-full grid grid-cols-2 justify-center items-center;

    .energyusage_today {
      @apply h-full pr-4 flex flex-col justify-center items-start border-r-1 border-[#E5E5E5];
    }

    .energyusage_dailyaverage {
      @apply h-full pl-9 flex flex-col justify-center items-start;
    }
  }
}
