.dataBox_container {
  @apply w-full h-full gap-4 p-4 bg-white rounded-lg border-1 flex justify-start items-center;

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.databox_data {
  @apply w-[80%] flex flex-col justify-between items-start;
}

.databox_label {
  @apply text-xs lg:text-body1 text-font-textcolor2;
}

.databox_value {
  @apply text-xl xl:text-[26px] leading-7 mt-1 text-font-textcolor1;
}
