.feedback_container {
  @apply w-full h-full bg-others-white shadow-md rounded-md;

  .feedback_card_title {
    @apply py-5 w-full flex justify-between items-center font-medium px-3;
  }

  .feedback_list {
    @apply overflow-y-auto 2xl:max-h-[456px] 2xl:overflow-x-hidden 2xl:overflow-y-auto;

    @media screen and (min-width: 1821px) {
      @apply max-h-[440px];
    }

    .feedback_item {
      @apply p-3 hover:bg-[#F9F9F9] border-b-1;

      .feedback_details {
        @apply flex justify-between items-start;

        .feedback_name_detail {
          @apply flex flex-col items-start;

          .feedback_name {
            @apply font-medium text-smalltext text-font-textcolor1;
          }
          .feedback_user_role {
            @apply text-body2 text-font-textcolor3;
          }
        }
        .feedback_datetimr {
          @apply text-body2 text-font-textcolor3;
        }
      }

      .feedback_desc {
        @apply text-body2 text-font-textcolor1 py-2;
      }
    }
  }
}
