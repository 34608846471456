.revenueofsubscriptions_container {
  @apply w-full h-full flex flex-col bg-others-white shadow-md rounded-md;
}

.revenueofsubscriptions_revenue {
  @apply w-full flex flex-col items-start p-4;
}

.revenueofsubscriptions_badge {
  @apply text-body2 font-medium text-others-black rounded-full px-2 py-1 bg-black bg-opacity-[0.05];
}
.revenueofsubscriptions_graph {
  @apply w-full;
}

.revenueofsubscriptions_menu {
  @apply flex justify-end items-center px-5 py-3;
}

.revenueofsubscriptions_data {
  @apply w-full flex justify-between items-center;
}
