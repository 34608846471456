.left_menu_close {
  @apply ml-[79px];
}

.left_menu_open {
  @apply ml-[300px];
}

.content_body {
  @apply pt-[110px] bg-others-background min-h-screen;
  transition: all 0.2s ease;
}
