.sidebar_section {
  @apply fixed h-full top-0 bottom-0;
}

.sidebar_logo {
  @apply flex fixed justify-center items-center bg-font-textcolor1 m-auto z-10 h-20 pl-0;
  // transition: all .2s ease;
}

.sidebar_footer {
  @apply text-body2 py-3 flex justify-center text-font-textcolor3 items-center text-center;
  letter-spacing: 0.02em;
}

.sidebar_menulink {
  @apply text-mediumtext font-normal flex justify-between ml-3;
}

.sidebar_submenulink {
  @apply text-mediumtext font-normal flex justify-between;
}

.sidebar_main {
  @apply flex flex-col overflow-hidden;
}

.sidebar_section::-webkit-scrollbar {
  display: none;
}
.sidebar_section {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
